import * as React from 'react'
import { TwitchPlayer } from 'react-twitch-embed'
import './styles.css'

const TwitchView = ({ channel, displayName = channel }) => {
  // let showChat = useIsStreaming(channel)
  const [isOnline, setOnline] = React.useState(false)
  const player = React.createRef()
  const stopPlaying = () => player.current?.pause()

  return (
    <div>
      <div>
        <TwitchPlayer
          channel={channel}
          width="100%"
          height="100%"
          onOnline={() => setOnline(true)}
          onOffline={() => setOnline(false)}
          onReady={(p) => {
            player.current = p
          }}
          parent={['codesandbox.io', 'csb.app']}
        />
      </div>
      <div className="twitch-link">
        <a href={`https://www.twitch.tv/${channel}`} onClick={stopPlaying}>
          {isOnline ? (
            <>
              {displayName} is currently streaming! Click here to watch and chat
              live on Twitch
            </>
          ) : (
            <>Click here to see recent videos from {displayName} on Twitch</>
          )}
        </a>
      </div>
    </div>
  )
}
export default TwitchView;

// export default function Twitch() {
//   return (
//     <div className="App">
//       <TwitchView channel="valorant" displayName="The Entire Robot" />
//     </div>
//   )
// }
